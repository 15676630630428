<template>
  <div class="NotFound py-4">
    <div class="text-white">
      <h1 class="text-2xl font-weight-bold">404</h1>
      <div>Page not found.</div>
      <div class="text-white-50">
        The page you are trying to get to never existed in this reality, or has
        migrated to a parallel universe.
      </div>
      <div class="text-white-50">
        <br />Try going back to home page and repeating your action. Or, contact
        helpdesk for support.
      </div>
    </div>
  </div>
</template>

<style>
.paragraph-text {
  font-size: 18px;
}
</style>